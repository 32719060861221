import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

           
import image1 from "../img/news/1.jpg";
import image2 from "../img/news/2.jpg";
import image3 from "../img/news/3.jpg";
import image4 from "../img/news/4.jpg";



const cards = [
  {
    id: 1,
    title: "Tıklayın, tek adımda ödeme yapın",
    content:
      "Zamanınıza değer veriyoruz. Tek adımda işlemlerinizi kolayca yapabileceğiniz e-tahsilat sistemimizi kullanabilirsiniz. ",
    image: image1,
    link: "https://yucelkompozit.oduyo.com.tr/b2b/login",
  },
  {
    id: 2,
    title: "Yücel Kompozit Bursa’da",
    content:"Yerli ve yabancı lider hammadde üreticilerinin temsilcisi veya bayisi olarak her ölçekteki kompozit üretimine uygun ürünler sunan Yücel Kompozit Bursa Şubesi, Osmangazi’de özel pazarlama ekibi ve deposuyla hizmetinizde.",
    image: image2,
    link: "/",
  },
  {
    id: 3,
    title: "İzmir İnşaatçılar Çarşısı Şubesi yenilendi",
    content:
      "İzmir’de yapı malzemeleri sektörünün kalbinin attığı Yenişehir İnşaatçılar Çarşısı’ndaki Yücel Kompozit şubesi yepyeni yüzü ve konforlu ortamıyla hizmetinizde.",
    image: image3,
    link: "/",
  },
  {
    id: 4,
    title: "Kompozit sektörünün güçlü dostu",
    content:`Her sektörden kompozit üreticilerinin ihtiyaç duydukları tüm kompozit malzeme ve ekipman Yücel Kompozit’in uzman satış ekibinin desteğiyle tam zamanında yerine ulaştırılıyor. Yücel Kompozit; ünlü markaların seçkin ürünlerini tek adreste topluyor`,
    image: image4,
    link: "/",
  },
  
];
const SliderCards = () => {
  const [current, setCurrent] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const breakpoint = 768;

  const handleNextSlide = useCallback(() => {
    if (window.innerWidth < breakpoint) {
      setCurrent((prev) => (prev >= cards.length - 1 ? 0 : prev + 1));
    } else {
      setCurrent((prev) => (prev >= cards.length - 3 ? 0 : prev + 1));
    }
  }, []);

  const handlePrevSlide = useCallback(() => {
    if (window.innerWidth < breakpoint) {
      setCurrent((prev) => (prev === 0 ? cards.length - 1 : prev - 1));
    } else {
      setCurrent((prev) => (prev === 0 ? cards.length - 3 : prev - 1));
    }
  }, []);

  useEffect(() => {
    const resizeListener = () => {
      const containerWidth =
        document.querySelector(".slider-container").offsetWidth;
      setCardWidth(
        containerWidth < breakpoint ? containerWidth : containerWidth / 3
      );
    };

    resizeListener();
    window.addEventListener("resize", resizeListener);

    return () => window.removeEventListener("resize", resizeListener);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        handleNextSlide();
      } else if (event.key === "ArrowLeft") {
        handlePrevSlide();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleNextSlide, handlePrevSlide]);

  return (
    <div className="relative w-full p-8 max-w-[90rem] mx-auto h-full font-sans overflow-hidden">
      <h1 className="text-3xl m-6 font-bold border-[#373A40] border-b-4 text-[#686D76]  ">
        Haberler
      </h1>

      <div className="slider-container relative">
        <button
          onClick={handlePrevSlide}
          className="absolute left-4 md:left-4 z-10 p-2 md:p-3 bg-red-500 text-white top-0  transform -translate-y-1/2 rounded-full shadow-lg hover:bg-orange-700 transition duration-300"
        >
          <FaArrowLeft size={16} />
        </button>
        <button
          onClick={handleNextSlide}
          className="absolute left-16 md:left-20 z-10 p-2 md:p-3 bg-red-500 text-white top-0  transform -translate-y-1/2 rounded-full shadow-lg hover:bg-orange-700 transition duration-300"
        >
          <FaArrowRight size={16} />
        </button>
        <div
          className="flex h-full transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(-${
              current * (cardWidth < breakpoint ? cardWidth : cardWidth / 3)
            }px)`,
          }}
        >
          {cards.map((card, index) => (
            <Link
              to={card.link}
              key={card.id}
              className="w-full mt-6 sm:w-1/2 md:w-1/3 lg:w-1/3 flex-shrink-0 flex items-start justify-center px-2"
              style={{ width: `${cardWidth}px`, minWidth: `${cardWidth}px` }}
            >
              <motion.div
                className="bg-[#686D76] items-center flex flex-col shadow-black  text-white p-3 md:p-4 rounded-3xl shadow-md font-sans w-full mx-2"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.05 }}
              >
                <img
                  src={card.image}
                  alt={card.title}
                  className="w-[15rem]  h-35 md:h-40 object-fill drop-shadow-2xl shadow-black shadow-xl rounded-3xl"
                />
                <div className="px-2 py-4 mt-6 bg-[#EEEEEE] shadow-lg rounded-lg">
                  <h2 className="text-lg md:text-xl text-gray-800 font-bold mb-2">
                    {card.title}
                  </h2>
                  <p className="text-sm md:text-md text-gray-600 font-medium">
                    {card.content}
                  </p>
                </div>
              </motion.div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SliderCards;







 