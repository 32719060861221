import React from "react";
import Carousel from "../components/Carousel";
import SliderCards from "../components/SliderCards";
import SliderNews from "../components/SliderNews";
import SliderBrands from '../components/SliderBrands'
import { Helmet } from "react-helmet-async";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Ana Sayfa - Yücel Kompozit</title>
        <meta name="description" content="Yücel Kompozit'in ana sayfası. Kompozit malzemeler, ürünler ve hizmetler hakkında bilgi edinin." />
        <meta name="keywords" content="kompozit, reçine, jelkot, elyaf, yücel kompozit" />
        <meta property="og:title" content="Ana Sayfa - Yücel Kompozit" />
        <meta property="og:description" content="Yücel Kompozit'in ana sayfası. Kompozit malzemeler, ürünler ve hizmetler hakkında bilgi edinin." />
        {/* Diğer meta etiketleri */}
      </Helmet>
      
      <div>
        <Carousel />
      </div>
      <div  className='flex justify-center items-center '>
        <SliderNews />
      </div>
      
      <div  className='flex justify-center items-center '>
        <SliderCards />
      </div>
      <div  className='flex justify-center items-center '>
        <SliderBrands />
      </div>
    </div>
  );
};

export default Home;
